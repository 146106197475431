<template>
  <div class="container">
    <div v-if="isLoading && !isList">Loading...</div>

    <div v-if="isList">No Data Found</div>
    <div v-if="!isLoading && !isList" class="row">
      
      <table class="table table-hover table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">Onsite Admin Panel - CheckIn/CheckOut 
              <div style="text-align: right;"><span style="margin-right: 10px;" :hidden ="!isDownloadDisabled" >Downloading Excel...</span><button class="btn btn-primary" @click="downloadExcel()"
          :hidden="isList" :disabled="isDownloadDisabled" style="float: inline-end;">Download</button></div></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account in accounts" :key="account.accountId">
            <div class="col resourceCol">
              <div class="row">
                <td>
                  <b>{{ account.accountId }} - {{ account.accountName }} - {{ account.region }}</b>
                </td>
              </div>
              <div class="row">
                <td>
                  <div v-for="resource in account.resourceDetails" :key="resource.resourceId" style="padding-bottom: 5px">
                    <div class="col">
                      <div class="row">
                        <span class="resourceName">{{ resource.resourceName }}
                        </span>

                        <span class="resourceAttendance">
                          [ In -
                          <span v-if="resource.checkInDisplayDateTime == null" style="color: red">
                            Not Done</span>
                          <span v-if="resource.checkInDisplayDateTime != null">
                            {{ resource.checkInDisplayDateTime }}</span>
                          | Out -
                          <span v-if="resource.checkOutDisplayDateTime == null" style="color: red">
                            Not Done</span>
                          <span v-if="resource.checkOutDisplayDateTime != null">
                            {{ resource.checkOutDisplayDateTime }}</span>
                          ]
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import * as XLSX from 'xlsx';

export default {
  name: "OnsiteAdminPanel",
  props: {
    title: String,
  },
  data() {
    return {
      accounts: [],
      isLoading: true,
      isList: false,
      isDownloadDisabled: false
    };
  },
  created() {
    this.getCurrentIp();
  },
  methods: {
    getCurrentIp() {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.clientIP = ip.toString();
          console.log("Client IP", this.clientIP);
          this.getOfficeIP(this.clientIP);
        });
    },

    async getOfficeIP(clientIP) {
      this.officeIP = await axios.get(
        "https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/GetOfficeIP?clientIP=" +
        clientIP
      );
      //this.officeIP = await axios.get('https://localhost:44382/api/v1/Account/GetOfficeIP?clientIP=' + clientIP);
      console.log("Office IP", this.officeIP.data);
      if (this.officeIP.data) {
        this.fetchAccountData();
      } else {
        alert("IP Address is not Valid!");
      }
    },
    async fetchAccountData() {
      try {
        const response = await axios.get(
          "https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/GetOnsiteAttendance"
        );
        //const response = await axios.get("https://localhost:44382/api/v1/Account/GetOnsiteAttendance");
        console.log(response);
        this.accounts = response.data.responseData;
        this.isList =
          response.data.responseData == "No Data Found" ? true : false;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = true;
        console.error(error);
      }
    },
    downloadExcel() {
      this.isDownloadDisabled = true;
      //axios.get("https://localhost:44382/api/v1/Account/ExportGetOnsiteAttendance").
      axios.get("https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/ExportGetOnsiteAttendance").then((response) => {
        console.log(response);
        if(response.data.isSuccess){
          this.isDownloadDisabled = false;
          this.createExcel(response.data.responseData);
        }
        else{
          this.isDownloadDisabled = false;
          alert(response.data.responseData);
        }
        
      })
    },

    createExcel(data) {
      const ws = XLSX.utils.json_to_sheet(data);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      var today = new Date();

      const fileName = 'CheckInCheckOutData'+ today.getDate().toString()+(today.getMonth()+1)+today.getFullYear() +'.xlsx';

      if (navigator.msSaveBlob) {
        // For IE
        navigator.msSaveBlob(blob, fileName);
      } else {
        // For modern browsers
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px;
}

.resourceName {
  color: green;
  text-decoration: underline;
}

.resourceAttendance {
  color: mediumblue;
}

.resourceCol {
  text-align: left;
  padding-left: 20px;
}
</style>
