import { createApp } from 'vue'
import App from './App.vue';
import HelloWorld from "./components/HelloWorld.vue";
import OnsiteAdminPanel from "./components/OnsiteAdminPanel.vue";
import OnsiteAdminPanel_Old from "./components/OnsiteAdminPanel_Old.vue";
import OnsiteAttendancePanel from "./components/OnsiteAttendancePanel.vue";
import OnsiteResourceCheckInCheckOut from './components/OnsiteResourceCheckInCheckOut.vue';
import 'bootstrap-icons/font/bootstrap-icons.css';

import {createRouter, createWebHistory} from 'vue-router'  

const routes = [
  {
    path: '/',
    name: 'Weclome',
    component: HelloWorld
  },{
    path: '/onsiteresource/:id',
    name: 'OnsiteResource',
    component: OnsiteResourceCheckInCheckOut,
  },{
    path: '/onsiteadminpanel_old',
    name: 'OnsiteAdminPanel_Old',
    component: OnsiteAdminPanel_Old,
  },{
    path: '/onsiteadminpanel',
    name: 'OnsiteAdminPanel',
    component: OnsiteAdminPanel,
  },{
    path: '/onsiteattendancepanel',
    name: 'OnsiteAttendancePanel',
    component: OnsiteAttendancePanel,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

createApp(App).use(router).mount('#app')


