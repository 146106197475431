<template>
  <div class="container">
    <div class="adminHeader col-md-12 col-xs-12 col-sm-12">
      Onsite Attendance Panel - CheckIn/CheckOut
    </div>
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="card">
          <div class="card-body">
            <div>
                <select v-model="selectedRegion" class="serviceDropdown" @change="onChangeRegion($event)" style="margin-right: 100px;">
                  <option value="">PLEASE SELECT REGION</option>
                  <option value="All">All</option>
                  <option v-for="item in regionList" :value="item" :key="item">
                    {{ item }}</option>
                </select>

                <select v-model="selectedSc" class="serviceDropdown" @change="onChange($event)" :disabled="selectedRegion == ''">
                  <option value="">PLEASE SELECT SERVICE CENTER</option>
                  <option value="All">All</option>
                  <option v-for="item in serviceCenterList" :value="item.serviceCenter_External_Reference_Id"
                    :key="item.serviceCenter_External_Reference_Id">
                    {{ item.serviceCenter_Name }}</option>
                </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading && selectedSc != ''">Loading...</div>
    <div class="notFound" v-if="isList">No Data Found</div>
    <div v-if="!isLoading && !isList && isServiceList" class="row" style="margin-top: 20px;">

      <div><button class="btn btn-primary" @click="downloadExcel(selectedSc)" :disabled="selectedSc == ''"
          :hidden="isList" style="float: inline-end;">Download</button></div>

      <div v-for="account in accounts" :key="account.accountId">
        <div class="col resourceCol">
          <div class="row">
            <td>
              <b>{{ account.accountId }} - {{ account.accountName }} - {{ account.region }} </b>
            </td>
          </div>
          <div class="row">
            <div class="col-md-6">
              <td>
                <div v-for="resource in account.resourceDetails" :key="resource.resourceId" style="padding-bottom: 5px">
                  <div class="row">
                    <div class="row">
                      <span class="resourceName">{{ resource.resourceName }} <span v-if="resource.resourceEmployeeId != null && resource.resourceEmployeeId != ''" style="font-weight: 600; color: midnightblue;">
                        - {{ resource.resourceEmployeeId }}
                      </span>
                      </span>

                      <div>
                        <span class="resourceAttendance">
                          [ In -
                          <span v-if="resource.checkInDisplayDateTime == null" style="color: red">
                            Not Done</span>
                          <span v-if="resource.checkInDisplayDateTime != null">
                            {{ resource.checkInDisplayDateTime }}</span>
                          | Out -
                          <span v-if="resource.checkOutDisplayDateTime == null" style="color: red">
                            Not Done</span>
                          <span v-if="resource.checkOutDisplayDateTime != null">
                            {{ resource.checkOutDisplayDateTime }} </span>
                          ]

                        </span>
                        <span class="shift" v-if="resource.shift_Detail != null">

                          <span v-if="!resource.isShift1">
                            Shift 1 <span class="dot"></span>
                          </span>
                          <span v-if="resource.isShift1">
                            Shift 1 <span style="background-color: rgb(12, 229, 12)" class="dot"></span>
                          </span>
                          <span v-if="!resource.isShift2">
                            Shift 2 <span class="dot"></span>
                          </span>
                          <span v-if="resource.isShift2">
                            Shift 2 <span style="background-color: rgb(12, 229, 12)" class="dot"></span>
                          </span>
                          <span v-if="!resource.isShift3">
                            Shift 3 <span class="dot"></span>
                          </span>
                          <span v-if="resource.isShift3">
                            Shift 3 <span style="background-color: rgb(12, 229, 12)" class="dot"></span>
                          </span>

                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </div>
            <div class="col-md-6" v-if="account.shiftDetail != null">
              <div class="shiftParent" style="background-color: #736799;" v-if="account.shiftDetail != null">

                <div class="shift1">
                  <span v-if="!account.shift1">
                    Shift 1 <span class="dot"></span>
                  </span>
                  <span v-if="account.shift1">
                    Shift 1 <span style="background-color: rgb(12, 229, 12)" class="dot"></span>
                  </span>
                  <span> Rsc. Count - {{ account.shiftDetail[0].shift1 }}</span>
                </div>
                <div class="shift2">
                  <span v-if="!account.shift2">
                    Shift 2 <span class="dot"></span>
                  </span>
                  <span v-if="account.shift2">
                    Shift 2 <span style="background-color: rgb(12, 229, 12)" class="dot"></span>
                  </span>
                  <span> Rsc. Count - {{ account.shiftDetail[0].shift2 }}</span>
                </div>
                <div class="shift3">
                  <span v-if="!account.shift3">
                    Shift 3 <span class="dot"></span>
                  </span>
                  <span v-if="account.shift3">
                    Shift 3 <span style="background-color: rgb(12, 229, 12)" class="dot">
                    </span>
                  </span>
                  <span> Rsc. Count - {{ account.shiftDetail[0].shift3 }}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import * as XLSX from 'xlsx';

export default {
  name: "OnsiteAttendancePanel",
  props: {
    title: String,
  },
  data() {
    return {
      accounts: [],
      isLoading: true,
      isList: false,
      serviceCenterList: [],
      mainServiceCenterList: [],
      isServiceList: false,
      selectedSc: "",
      regionList: [],
      selectedRegion: "",
      selectedSCList: []
    };
  },
  created() {
    //this.getCurrentIp(); //uncomment when need to restrict by IP & comment below function
    this.getServiceCenter();
  },
  methods: {
    getCurrentIp() {
      fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.clientIP = ip.toString();
          //console.log("Client IP", this.clientIP);
          this.getOfficeIP(this.clientIP);
        });
    },

    async getOfficeIP(clientIP) {
      this.officeIP = await axios.get(
        "https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/GetOfficeIP?clientIP=" +
        clientIP
      );
      //this.officeIP = await axios.get('https://localhost:44382/api/v1/Account/GetOfficeIP?clientIP=' + clientIP);
      //console.log("Office IP", this.officeIP.data);
      if (this.officeIP.data) {
        this.getServiceCenter();
      } else {
        alert("IP Address is not Valid!");
      }
    },

    async getServiceCenter() {
      try {
        const response = await axios.get('https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/GetServiceCenter');
        //const response = await axios.get('https://localhost:44382/api/v1/Account/GetServiceCenter');
        //console.log("ServiceCenterList", response);
        if (response.data.isSuccess) {
          this.serviceCenterList = "";
          this.mainServiceCenterList = response.data.responseData;
          this.isServiceList =
            response.data.responseData == "No Data Found" ? false : true;
          this.regionList = Array.from(new Set(this.mainServiceCenterList.map(a => a.region))).toSorted();
          //console.log("RegionList", this.regionList);
        }
        else {
          this.isServiceList = false;
          alert(response.data.responseData);
        }

      } catch (error) {
        console.error(error);
      }
    },


    onChangeRegion(event) {
      console.log(event.target.value, "Region :", this.selectedRegion);
      this.selectedSc = "";
      this.serviceCenterList = [];
      this.accounts = [];
      if (this.selectedRegion == "All") {
        this.serviceCenterList = this.mainServiceCenterList;
      }
      else {
        this.serviceCenterList = this.mainServiceCenterList.filter(a => a.region == this.selectedRegion);
      }

    },

    onChange(event) {
      console.log(event.target.value, "SC :", this.selectedSc);
      this.accounts = [];
      if (this.selectedSc != "") {
        if(this.selectedSc == "All" && this.selectedRegion != "All"){

          var tempSCList = this.serviceCenterList.map(s => s.serviceCenter_External_Reference_Id);
          this.selectedSCList = tempSCList.join(',');
          //console.log("Multiple SC", this.selectedSCList);
          this.GetAccountsByServiceCenterId(this.selectedSCList);
        }
        else{
          this.GetAccountsByServiceCenterId(this.selectedSc);
        }
        
      }
    },

    async GetAccountsByServiceCenterId(serviceCenterId) {
      try {
        this.isLoading = true;
        const response = await axios.get("https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/GetAccountsByServiceCenterId?serviceCenterId=" + serviceCenterId);
        //const response = await axios.get("https://localhost:44382/api/v1/Account/GetAccountsByServiceCenterId?serviceCenterId=" + serviceCenterId);
        //console.log(response);
        if (response.data.isSuccess) {
          this.accounts = response.data.responseData;
          this.isList =
            response.data.responseData == "No Data Found" ? true : false;
          this.isLoading = false;
        }
        else {
          alert(response.data.responseData);
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = true;
        console.error(error);
      }
    },

    downloadExcel(serviceCenterId) {
      if(serviceCenterId != ""){
        if(this.selectedSc == "All" && this.selectedRegion != "All"){
        this.exportGetAccountsByServiceCenterId(this.selectedSCList);
      }
      else{
        this.exportGetAccountsByServiceCenterId(this.selectedSc);
      }
      }
    },

    exportGetAccountsByServiceCenterId(serviceCenterId){
      //axios.get("https://localhost:44382/api/v1/Account/ExportGetAccountsByServiceCenterId?serviceCenterId=" + serviceCenterId).then
      axios.get("https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/ExportGetAccountsByServiceCenterId?serviceCenterId=" + serviceCenterId).then((response) => {
        //console.log(response);
        if (response.data.isSuccess) {
          this.createExcel(response.data.responseData);
        }
        else {
          alert(response.data.responseData);
        }

      })

    },

    createExcel(data) {
      const ws = XLSX.utils.json_to_sheet(data);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var today = new Date();
      const fileName = 'CheckInCheckOutData' + '_' + this.selectedRegion + '_' + today.getDate().toString() + (today.getMonth() + 1) + today.getFullYear() + '.xlsx';

      if (navigator.msSaveBlob) {
        // For IE
        navigator.msSaveBlob(blob, fileName);
      } else {
        // For modern browsers
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

  },
};
</script>

<style scoped>
.customTable {
  font-size: 12px;
  border: 1px solid black !important;
  border-collapse: collapse;
  text-align: center;
  margin-top: 30px;
  margin-left: 180px;
}

.customTable th {
  border: 1px solid black !important;
  width: 35%;
}

.customTable td {
  border: 1px solid black !important;
}

.resourceName {
  color: green;
  text-decoration: underline;
}

.resourceAttendance {
  color: mediumblue;
}

.resourceCol {
  text-align: left;
  padding-left: 20px;
}

.serviceDropdown {
  font-weight: 600;
  padding: 10px;
  border-radius: 10px;
  background-color: #e2ebf3;
}

.adminHeader {
  font-size: 28px;
  font-weight: 600;
  background-color: #574476;
  color: #a9d5c5;
  border-radius: 10px;
  padding: 12px;
}

.notFound {
  background-color: gainsboro;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
  font-weight: 600;
}

.shiftParent {
  background-color: rgb(115, 103, 153);
  text-align: center;
  margin-left: 210px;
  box-shadow: 0px 0px 5px 2px #bfb6b6;
  border: 1px solid black;
  color: white;
  width: 50%;
  border-radius: 7px;
  padding: 5px
}

.shift {
  background-color: #7e7070;
  color: white;
  width: 30%;
  border-radius: 7px;
  margin-left: 10px;
  padding: 4px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0px 0px 3px 1px;
}
</style>
