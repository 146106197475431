<template>
  <div class="container">
    <div v-if="isLoading && !isList">Loading...</div>
    <div v-if="isList">No Data Found</div>
    <div
      v-if="!isLoading && !isList"
      class="row">
      <table class="table table-hover table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">Onsite Resource Check-In Check-Out</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="account in accounts"
            :key="account.accountId">
            <div class="col resourceCol">
              <div class="row">
                <td>
                  <b>{{ account.accountName }}</b>
                </td>
              </div>
              <div class="row">
                <td>
                  <div
                    v-for="resource in account.resourceDetails"
                    :key="resource.resourceId"
                    style="padding-bottom: 5px">
                    <div class="col">
                      <div class="row">
                        <span class="resourceName"
                          >{{ resource.resourceName }}
                        </span>

                        <span class="resourceAttendance">
                          [ In -
                          <span
                            v-if="resource.checkInDisplayDateTime == null"
                            style="color: red">
                            Not Done</span
                          >
                          <span v-if="resource.checkInDisplayDateTime != null">
                            {{ resource.checkInDisplayDateTime }}</span
                          >
                          | Out -
                          <span
                            v-if="resource.checkOutDisplayDateTime == null"
                            style="color: red">
                            Not Done</span
                          >
                          <span v-if="resource.checkOutDisplayDateTime != null">
                            {{ resource.checkOutDisplayDateTime }}</span
                          >
                          ]
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </div>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "OnsiteResourceCheckInCheckOut",
  props: {
    title: String,
  },
  data() {
    return {
      accounts: [],
      isLoading: true,
      isList: false,
      id: this.$route.params.id,
    };
  },
  created() {
    this.fetchAccountData();
  },
  methods: {
    async fetchAccountData() {
      try {
        console.log(this.id);
        //const response = await axios.get('https://localhost:44382/api/v1/Account/GetOnsiteResourceCheckInCheckOut?resourceId=' + this.id);
        const response = await axios.get(
          "https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/GetOnsiteResourceCheckInCheckOut?resourceId=" +
            this.id
        );
        console.log(response);
        this.accounts = response.data.responseData;
        this.isList =
          response.data.responseData == "No Data Found" ? true : false;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = true;
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px;
}

.resourceName {
  color: green;
  text-decoration: underline;
}

.resourceAttendance {
  color: mediumblue;
}

.resourceCol {
  text-align: left;
  padding-left: 20px;
}
</style>
