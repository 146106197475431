<template>
    <div class="container">
        <!-- <div v-if="isLoading && !isList">Loading...</div> -->
        <!-- <div v-if="!isList">No Data Found</div> -->
        <div>
            <div class="adminHeader col-md-12 col-xs-12 col-sm-12">
                Onsite Admin Panel
            </div>
            <div class="row dropDown">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <select v-model="selectedSc" class="serviceDropdown" @change="onChange($event)">
                                <option value="">Please select service center</option>
                                <option v-for="item in serviceCenterList" :value="item.serviceCenter_External_Reference_Id"
                                    :key="item.serviceCenter_External_Reference_Id">
                                    {{ item.serviceCenter_Name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <select v-model="selectedResource" class="resourceDropdown"
                                :disabled="isResourceDropdownDisable" @change="onResourceChange($event)">
                                <option value="">Please select resource</option>
                                <option v-for="item in resourceList" :value="item.external_Reference_Id"
                                    :key="item.external_Reference_Id">
                                    {{ item.resource_Name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isServiceList && !isAccountList && isLoading">Please wait loading...</div>
            <div v-if="isServiceList && selectedResource != '' && !isAccountList && !isLoading">Accounts Not Found</div>
            <div class="noCheckInCurrentDate" v-if="isNoCheckInCurrentDate">No Check-In Found against any accounts in
                current date</div>
                <div class="noCheckInCurrentDate" v-if="isNoAccountFound">Accounts Not Available</div>
            <div v-if="isServiceList && isAccountList" class="row">
                <!-- <div  class="row"> -->
                <div class="col-md-12">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Account No</th>
                                <th scope="col">Account Name</th>
                                <th :hidden="selectedResource != ''" scope="col">Check-In Time</th>
                                <th :hidden="selectedResource != ''" scope="col">Check-Out Time</th>
                                <th hidden scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(account, index) in accountList" :key="account.accountId">
                                <th scope="row">{{ index + 1 }}</th>
                                <td>{{ account.accountNo }}</td>
                                <td>{{ account.accountName }}</td>
                                <td :hidden="selectedResource != ''">
                                    <div>
                                        {{ account.recordedDisplayDateTime }}
                                    </div>
                                    <div style="color: #3e24c5;">
                                        {{ account.resourceName }}
                                    </div>
                                </td>
                                <td :hidden="selectedResource != ''">
                                    <div>
                                        {{ account.checkOutDisplayDateTime }}
                                    </div>
                                </td>
                                <td hidden><input type="datetime-local" :id="'date_' + index"
                                        style="padding: 2px;
    border-radius: 10px;" v-model="account.recordedDateTime">
                                    <i class="bi bi-arrow-right-square-fill"
                                        style="color: red; cursor: pointer;font-size: 20px;padding-left: 20px "
                                        title="Check-In"
                                        @click="checkIn(account.accountNo, account.accountName, account.recordedDateTime)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name: "OnsiteAdminPanel_Old",
    props: {
        title: String,
    },
    data() {
        return {
            clientIP: "",
            officeIP: "",
            serviceCenterList: [],
            resourceList: [],
            accountList: [],
            isLoading: false,
            isServiceList: false,
            isResourceList: false,
            isAccountList: false,
            selectedSc: "",
            selectedResource: "",
            selectedAccount: "",
            isResourceDropdownDisable: true,
            checkInTime: "",
            isNoCheckInCurrentDate: false,
            isNoAccountFound: false

        };
    },
    created() {
        this.getCurrentIp();


    },
    methods: {
        getCurrentIp() {
            fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    this.clientIP = ip.toString();
                    console.log("Client IP", this.clientIP);
                    this.getOfficeIP(this.clientIP);
                });
        },

        async getOfficeIP(clientIP) {
            this.officeIP = await axios.get('https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/GetOfficeIP?clientIP=' + clientIP);
            //this.officeIP = await axios.get('https://localhost:44382/api/v1/Account/GetOfficeIP?clientIP=' + clientIP);
            console.log("Office IP", this.officeIP.data);
            if (this.officeIP.data) {
                this.getServiceCenter();
            }
            else {
                alert("IP Address is not Valid!")
            }
        },

        async getServiceCenter() {
            try {
                const response = await axios.get('https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/GetServiceCenter');
                //const response = await axios.get('https://localhost:44382/api/v1/Account/GetServiceCenter');
                console.log("ServiceCenterList", response);
                if (response.data.isSuccess) {
                    this.serviceCenterList = response.data.responseData;
                    this.isServiceList =
                        response.data.responseData == "No Data Found" ? false : true;
                }
                else {
                    this.isServiceList = false;
                    alert(response.data.responseData);
                }

            } catch (error) {
                console.error(error);
            }
        },

        onChange(event) {
            console.log(event.target.value, "SC :", this.selectedSc);
            this.accountList = [];
            this.selectedResource = ""
            if (this.selectedSc != "") {
                this.getResourceByServiceCenterId(this.selectedSc);
            }
        },

        async getResourceByServiceCenterId(serviceCenterId) {
            try {
                this.isLoading = true;
                this.isNoCheckInCurrentDate = false;
                this.isNoAccountFound = false;
                //const response = await axios.get('https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/GetResourceByServiceCenterId?serviceCenterId=' + serviceCenterId);
                const response = await axios.get('https://localhost:44382/api/v1/Account/GetResourceByServiceCenterId?serviceCenterId=' + serviceCenterId);
                console.log("ResourceList", response);
                if (response.data.isSuccess) {
                    this.isResourceList =
                        response.data.responseData == "Resource Not Available" ? false : true;
                    if (this.isResourceList) {
                        console.log("accountListService", response.data.responseData.accountList);
                        this.resourceList = response.data.responseData.resourceList;
                        this.accountList = response.data.responseData.accountList;
                        this.isAccountList =
                            response.data.responseData.accountList == "No Account Found" || response.data.responseData.accountList.length == 0 ? false : true;
                        if (response.data.responseData.accountList.length == 0) {
                            this.isNoCheckInCurrentDate = true;
                        }
                        if(response.data.responseData.accountList == "No Account Found"){
                            this.isNoAccountFound = true;
                        }
                        this.isResourceDropdownDisable = false;
                        this.isLoading = false;
                    }
                    else {
                        alert(response.data.responseData);
                        this.selectedResource = "";
                        this.isResourceDropdownDisable = true;
                        this.isLoading = false;
                    }
                }
                else {
                    this.isResourceList = false;
                    this.isResourceDropdownDisable = true;
                    this.isLoading = false;
                    alert(response.data.responseData);
                }

            } catch (error) {
                console.error(error);
            }
        },

        onResourceChange(event) {
            this.accountList = [];
            this.isNoCheckInCurrentDate = false;
            this.isNoAccountFound = false;
            console.log(event.target.value, "Resource :", this.selectedResource);
            if (this.selectedResource != "") {
                this.getAccountByResourceId(this.selectedResource);
            }
            else {
                this.getResourceByServiceCenterId(this.selectedSc);
                this.isAccountList = false;
            }

        },

        async getAccountByResourceId(resourceId) {
            this.isLoading = true;
            try {
                const response = await axios.get('https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/GetAccountByResourceId?resourceId=' + resourceId);
                //const response = await axios.get('https://localhost:44382/api/v1/Account/GetAccountByResourceId?resourceId=' + resourceId);
                console.log("AccountList", response);
                if (response.data.isSuccess) {
                    this.isAccountList =
                        response.data.responseData == "No Data Found" ? false : true;
                    if (this.isAccountList) {
                        this.accountList = response.data.responseData;
                        this.isLoading = false;
                    }
                    else {
                        this.isAccountList = false;
                        this.isLoading = false;
                        //alert(response.data.responseData);
                    }
                }
                else {
                    this.accountList = false;
                    this.isLoading = false;
                    alert(response.data.responseData);
                }

            } catch (error) {
                console.error(error);
            }
        },

        checkIn(accountNo, accountName, recordedDateTime) {
            console.log("checkin", recordedDateTime);
            if (recordedDateTime != undefined && recordedDateTime != null && recordedDateTime != "") {
                var confirmCheckIn = confirm("Are you sure you want to Check-In?");

                if (confirmCheckIn) {
                    var checkInData = {
                        AccountName: accountName,
                        AccountId: accountNo,
                        ResourceName: this.resourceList.find(a => a.external_Reference_Id == this.selectedResource).resource_Name,
                        ResourceId: this.selectedResource,
                        ServiceCenter: this.serviceCenterList.find(a => a.serviceCenter_External_Reference_Id == this.selectedSc).serviceCenter_Name,
                        Region: this.serviceCenterList.find(a => a.serviceCenter_External_Reference_Id == this.selectedSc).region,
                        CheckInTime: recordedDateTime
                    }
                    console.log(checkInData);
                    this.adminCheckInAccount(checkInData);
                }
            }
            else {
                alert("Please Enter Check-In Time");
            }

        },

        async adminCheckInAccount(checkInData) {
            try {
                const response = await axios.post('https://connect.hicare.in/b2bwowonsiteapi/api/v1/Account/AdminCheckInAccount', checkInData);
                //const response = await axios.post('https://localhost:44382/api/v1/Account/AdminCheckInAccount', checkInData);
                console.log("AdminCheckIn", response);
                if (response.data.isSuccess) {
                    alert("Check-In Successful");
                    this.selectedResource = "";
                    this.getResourceByServiceCenterId(this.selectedSc);
                }
                else {
                    alert("Something Went Wrong!");
                    console.log(response.data.responseData);
                }

            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>
  
<style scoped>
.table {
    font-size: 14px;
}

.adminHeader {
    font-size: 28px;
    font-weight: 600;
    background-color: #574476;
    color: #a9d5c5;
    border-radius: 10px;
    padding: 12px;
}

.dropDown {
    padding: 10px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.serviceDropdown {
    font-weight: 600;
    padding: 10px;
    border-radius: 10px;
    background-color: #e2ebf3;
}

.resourceDropdown {
    font-weight: 600;
    padding: 10px;
    border-radius: 10px;
    background-color: #e2ebf3;
}

.noCheckInCurrentDate {
    background-color: gainsboro;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 5px;
    font-weight: 600;
}
</style>
  